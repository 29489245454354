import { useState } from "react";
import { setSearchParam } from "@/lib/window";
import { TitleView } from "./title-view";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Badge } from "./ui/badge";
import { PlanCard } from "./plan-card";
import { Plan } from "@/data/data";

export interface TabsProps {
    tabs: TabsProp[];
  }
  
  export interface TabsProp {
    title: string;
    value: string;
  }
  
export function DisplayPlans({ title, description, plans, tabs, defaultPlan, handleSelectedPlan }: { title: string, description: string, plans: Plan[], tabs: TabsProps, defaultPlan: string | undefined, handleSelectedPlan?: (plan: Plan) => Promise<void> }) {

    return (
      <>
        <TitleView
          title={title}
          description={description}
        />
        <PlanView 
          defaultPlan={defaultPlan}
          tabs={tabs}
          plans={plans}
          handleSelectedPlan={handleSelectedPlan}
        />
      </>
    )
  }

  export function PlanView({ tabs, plans, defaultPlan, handleSelectedPlan }: { tabs: TabsProps, plans: Plan[], defaultPlan: string | undefined, handleSelectedPlan?: (plan: Plan) => Promise<void> }) {
    const [selectedPlan, setSelectedPlan] = useState(defaultPlan || (tabs.tabs.length > 0 ? tabs.tabs[0].value : plans.length > 0 ? plans[0].interval : ""));
  
    const handleTabChange = (newValue: string) => {
      setSelectedPlan(newValue);
      // Update history
      const plan = plans.find((plan) => plan.interval === newValue);
      if (!plan) return;
      setSearchParam("p", btoa(plan.publicName));
    };
    

    return (
      <div className="flex justify-center space-y-0 px-3">
      <Tabs
        style={{ flex: "1" }}
        defaultValue={selectedPlan}
        onValueChange={(newValue) => handleTabChange(newValue)}
      >
        {tabs && tabs.tabs.length > 0 && 
          <div className="flex justify-center w-full">
              <TabsList
              className={`grid w-full grid-cols-${tabs.tabs.length} min-h-12 justify-center`}
              style={{ flex: "1", maxWidth: "400px" }}
              >
              {tabs.tabs.map((tab, index) => (
                <TabsTrigger key={index} value={tab.value} className="min-h-10  gap-2 px-4">
                  {tab.title}
                  {tab.value === 'year' && <Badge className="px-2">Pay less</Badge>}
                </TabsTrigger>
              ))}
              </TabsList>
          </div>
          }
        <div className="flex flex-col md:flex-row max-w-screen-md mx-auto space-x-0 md:space-x-6 md:justify-center items-center md:items-stretch">
          {plans.map((plan, index) => (
            <TabsContent
              className={`md:w-1/${tabs.tabs.length} max-w-96 w-full`}
              key={index}
              value={plan.interval}
            >
              <PlanCard plan={plan} handleSelectedPlan={handleSelectedPlan} />
            </TabsContent>
          ))}
        </div>
      </Tabs>
    </div>
    )
  }