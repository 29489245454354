import React from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { GetContentPlan } from "@/components/plan/plan-content";
import { Plan } from "@/data/data";
import { Badge } from "./ui/badge";

export function PlanCard({ plan, handleSelectedPlan }: { plan: Plan, handleSelectedPlan?: (plan: Plan) => Promise<void> }) {
  return (
    <Card className="flex-gro h-full flex flex-col">
      <CardHeader>
        <CardTitle className="text-2xl flex items-center justify-between">
          {plan.title}
          {plan.save && 
          <Badge className="text-base bg-yellow-100 text-yellow-600 shadow-none hover:bg-yellow-50">
            {plan.save}</Badge>
          }
        </CardTitle>
        <CardDescription className="min-h-11">
          {plan.subtitle}
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col w-full space-y-0 flex-grow">
        <GetContentPlan plan={plan} hasButton={true} handleSelectedPlan={handleSelectedPlan} />
      </CardContent>
    </Card>
  );
}
